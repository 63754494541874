<template>
  <v-form>
    <v-container>
      <v-form class="mr-3">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details="auto"
              v-model="userDetail.representative_name"
              :error-messages="representativeNameError"
              :label="$t('profile.representativeName')"
              :placeholder="$t('profile.representativeNamePlaceholder')"
              @blur="$v.userDetail.representative_name.$touch()"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              hide-details="auto"
              maxlength="14"
              v-model="userDetail.representative_phone"
              :error-messages="representativePhoneError"
              :label="$t('profile.representativePhone')"
              :placeholder="$t('profile.representativePhonePlaceholder')"
              @blur="$v.userDetail.representative_phone.$touch()"
              v-to-us-format
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-btn
              :loading="loading"
              :disabled="$v.$invalid && $v.$anyDirty && loading"
              large
              color="green darkan-4 green-gradient white--text"
              class="text-capitalize btn-submit"
              @click="update"
            >
              {{ $t("profile.submit") }}
              <template v-slot:loading>
                <v-icon>mdi-loading</v-icon>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import numberUtility from "@/utils/number.utility";
import ProfileMixin from "@/mixins/ProfileMixin";

export default {
  mixins: [ProfileMixin],
  data() {
    return {
      loading: false,
      userDetail: {},
    };
  },
  validations: {
    userDetail: {
      representative_name: {
        required,
      },
      representative_phone: {
        required,
        usFormat: (value) => {
          return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
        },
      },
    },
  },
  mounted() {
    if (this.user && this.user.user_detail) {
      this.userDetail = this.user.user_detail;
      this.userDetail.user_type = this.user.user_type;
      this.userDetail.representative_phone = numberUtility.toUsPhone(
        this.userDetail.representative_phone
      );
      this.userDetail.company_phone = numberUtility.toUsPhone(
        this.userDetail.company_phone
      );
    }
  },
  methods: {},
};
</script>
