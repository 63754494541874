<template>
  <v-form>
    <v-container>
      <v-form class="mr-3">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              hide-details="auto"
              v-model="userDetail.company_name"
              :error-messages="companyNameError"
              :label="$t('profile.companyName')"
              :placeholder="$t('profile.companyNamePlaceholder')"
              @blur="$v.userDetail.company_name.$touch()"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              hide-details="auto"
              v-model="userDetail.company_email"
              :error-messages="companyEmailError"
              :label="$t('profile.companyEmail')"
              :placeholder="$t('profile.companyEmailPlaceholder')"
              @blur="$v.userDetail.company_email.$touch()"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              hide-details="auto"
              maxlength="14"
              v-model="userDetail.company_phone"
              :error-messages="companyPhoneError"
              :label="$t('profile.companyPhone')"
              :placeholder="$t('profile.companyPhonePlaceholder')"
              @blur="$v.userDetail.company_phone.$touch()"
              v-to-us-format
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              hide-details="auto"
              v-model="userDetail.representative_name"
              :error-messages="representativeNameError"
              :label="$t('profile.representativeName')"
              :placeholder="$t('profile.representativeNamePlaceholder')"
              @blur="$v.userDetail.representative_name.$touch()"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              hide-details="auto"
              maxlength="14"
              v-model="userDetail.representative_phone"
              :error-messages="representativePhoneError"
              :label="$t('profile.representativePhone')"
              :placeholder="$t('profile.representativePhonePlaceholder')"
              @blur="$v.userDetail.representative_phone.$touch()"
              v-to-us-format
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <GooglePlace
              id="address"
              :isRequired="true"
              label="profile.address"
              validationMsg="profile.validations.addressIsRequired"
              :address="userDetail.address"
              @onAddressSelect="addressHandler"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              hide-details="auto"
              type="number"
              v-model="userDetail.zip_code"
              :error-messages="zipCodeError"
              :label="$t('profile.zipCode')"
              @blur="$v.userDetail.zip_code.$touch()"
              :placeholder="$t('profile.zipCodePlaceholder')"
              outlined
              maxlength="5/"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              hide-details="auto"
              v-model="userDetail.city"
              :error-messages="cityError"
              :label="$t('profile.city')"
              @blur="$v.userDetail.city.$touch()"
              :placeholder="$t('profile.cityPlaceholder')"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              hide-details="auto"
              v-model="userDetail.state"
              :error-messages="stateError"
              :label="$t('profile.state')"
              :placeholder="$t('profile.statePlaceholder')"
              @blur="$v.userDetail.state.$touch()"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12" v-if="user.user_type == 3">
            <span class="text--bold">{{ $t('coreBuying.companyLogo') }}</span>
            <div class="
                flex
                w-full
                h-screen
                items-center
                justify-center
                text-center
                fileupload
              " style="min-height: 56px;">
              <v-progress-circular v-if="imageLoader" indeterminate :size="30"
                color="green"></v-progress-circular>
              <div class="" v-if="!imageLoader">
                <image-uploader ref="logo" id="fileInput" :quality="0.9" outputFormat="verbose" :preview=false
                  :className="['d-none']" @input="onFileChange($event, 'logo')">
                  <label for="fileInput" slot="upload-label" class="node-image-uploader">
                    <figure>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <path class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z">
                        </path>
                      </svg>
                    </figure>
                    <span class="upload-caption" style="padding-left: 10px;padding-bottom: 10px;">Click to
                      upload</span>
                  </label>
                </image-uploader>
              </div>
              <div id="logo" class="select-image">
                <img v-if="image" class="logo" :src='image?.url100' alt=''>
              </div>
              <a id="logo-delIcon" v-if="image" @click="deleteImage('logo')" class="delicon"
                data-tooltip="Delete">
                <img src="../../assets/delete.svg" height="30" />
              </a>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <v-btn
              :loading="loading"
              hide-details="auto"
              :disabled="$v.$invalid && $v.$anyDirty && loading"
              large
              color="green darkan-4 green-gradient white--text"
              class="text-capitalize btn-submit"
              @click="update"
            >
              {{ $t("profile.submit") }}
              <template v-slot:loading>
                <v-icon>mdi-loading</v-icon>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import numberUtility from "@/utils/number.utility";
import ProfileMixin from "@/mixins/ProfileMixin";

export default {
  components: {
    GooglePlace: () => import("@/components/common/GooglePlace"),
  },

  mixins: [ProfileMixin],

  data() {
    return {
      loading: false,
      address: null,
      userDetail: {},
      validAddress: true,
      image: false,
      imageLoader: false,
    };
  },
  validations: {
    userDetail: {
      company_name: {
        required,
      },
      company_email: {
        required,
        email,
      },
      company_phone: {
        required,
        usFormat: (value) => {
          return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
        },
      },
      representative_name: {
        required,
      },
      representative_phone: {
        required,
        usFormat: (value) => {
          return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
        },
      },
      zip_code: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
    },
  },
  mounted() {
    if (this.user && this.user.user_detail) {
      this.userDetail = this.user.user_detail;
      this.userDetail.user_type = this.user.user_type;
      this.userDetail.representative_phone = numberUtility.toUsPhone(
        this.userDetail.representative_phone
      );
      this.userDetail.company_phone = numberUtility.toUsPhone(
        this.userDetail.company_phone
      );
    }
    if(this.user) {
      this.image = this.user?.medias[0];
    }
  },
  methods: {
    addressHandler(payload) {
      this.userDetail.address = payload.address;
      this.userDetail.state = payload.state;
      this.userDetail.zip_code = payload.zip;
      this.userDetail.city = payload.city;
      this.validAddress = payload.valid;
    },
    async onFileChange(fileData, type) {
      var selectedImage = await this.saveImage(fileData.dataUrl, type);
      this.image = selectedImage.data;
    },
    async saveImage(file, tag) {
      try {
        this.imageLoader = true;
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", "");
        await this.uploadImage(formData);
        this.imageLoader = false;
        return this.imageResponse;
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    deleteImage(type) {
      this.$refs[type].$el.children[1].value = "";
      this.image = false;
      if (this.isAttentionPost) {
        this.isAttentionPost = false;
      }
    },
  },
};
</script>
