<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label class="pb-4 pr-5">
                <span>
                  {{ $t("profile.title") }}
                </span>
              </label>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!isAssociate"
                large
                width="200"
                class="
                  mr-0
                  mb-4
                  log-out
                  create-post-btn
                  post-reply-btn
                  green
                  darken-4
                  green-gradient
                  white--text
                "
                @click="$router.push({ name: 'PreviousInvoices' })"
              >
                {{ $t("payment.previousInvoiceTitle") }}
              </v-btn>
            </v-card-title>
            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <div class="pa-10">
                <Associate v-if="isAssociate" />
                <Member v-else />
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Member from "../components/profile/Member";
import Associate from "../components/profile/Associate";

export default {
  name: "Profile",
  components: { Associate, Member },
  data() {
    return {
      address: null,
      userDetail: {},
      validAddress: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    isAssociate() {
      return !!this.user.parent_id;
    },
  },
  mounted() {},
  methods: {},
};
</script>
